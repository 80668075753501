/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Hero,
  VariableContent,
  List,
  ListItem,
  LeshenPhoneCTA,
  Link,
  Price,
  ModalWithState,
  Brandy,
  LinkButton,
  SplitContent,
  Image,
  ItemBank,
} from '@leshen/gatsby-theme-leshen'
import { Typography } from '@leshen/ui'
import { graphql, Link as GatsbyLink } from 'gatsby'

import SeoCtaBar from '../components/SeoCtaBar'

const StateTemplate = ({ data }) => {
  const {
    Meta_Description,
    State,
    State_Abbreviation,
    Promo_Code,
    Page_Path,
    Meta_Title,
  } = data.pageInfo.edges[0].node

  const stateAbbreviation = State_Abbreviation.toLowerCase()

  const passedData = {
    ...data,
    contentfulPage: {
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      seo: {
        canonical: `/availability/${stateAbbreviation}`,
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: [
        {
          shortName: 'Home',
          path: '/',
        },
        {
          shortName: 'Availability',
          path: '/availability',
        },
      ],
    },
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            customLink={GatsbyLink}
            backgroundColor="Primary"
            mainContent={
              <>
                <Typography variant="h1">
                  DIRECTV Is The Best Value In {State}
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>285+ premium channels</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>
                        Genie<sup>®</sup> HD DVR included
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Simple installation process</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.brandyPackage?.price}
                  bullets={data.brandyPackage.bullets}
                  variant="Default"
                >
                  <ModalWithState modalText="See offer details" heading="">
                    <Brandy
                      symbol={data.priceModalDisclaimer.symbol}
                      text={data.priceModalDisclaimer.text}
                      variant="legal"
                    />
                  </ModalWithState>
                </Price>
                <LeshenPhoneCTA variant="hero" color="tertiary">
                  Call
                </LeshenPhoneCTA>
                <Brandy
                  symbol={data.heroDisclaimer.symbol}
                  text={data.heroDisclaimer.text}
                  variant="legal"
                />
              </>
            }
            image={
              <Image
                data={{
                  ...data.heroDesktopImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.heroDesktopImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={{
                  ...data.heroMobileImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.heroMobileImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SeoCtaBar />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  Sports on DIRECTV in {State}
                </Typography>
                <Typography>
                  If you can’t get enough of your local {State} sports teams,
                  DIRECTV can’t be beat.{' '}
                  <GatsbyLink to="/channels/sports">DIRECTV Sports</GatsbyLink>{' '}
                  in {State} includes packages like MLB EXTRA INNINGS℠, and NBA
                  League Pass, and NHL<sup>®</sup> CENTER ICE<sup>®</sup>. Plus,
                  extra features like Enhanced Games and Highlights On Demand
                  bring you high-energy sports entertainment in {State}, anytime
                  you crave it. So whether you’re a soccer fan, a baseball fan,
                  or anywhere in between, DIRECTV in {State} has what you’re
                  looking for.
                </Typography>
              </>
            }
            image={
              <Image
                data={{
                  ...data.sportsImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.sportsImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">DIRECTV in {State}</Typography>
                <Typography>
                  If you live in {State}, DIRECTV can get you hundreds of
                  channels, with free{' '}
                  <GatsbyLink to="/channels/premium-channels">
                    premium channels
                  </GatsbyLink>{' '}
                  and equipment upgrades. Right now, DIRECTV customers in{' '}
                  {State} can get more than 285 channels, premium movie channels
                  included for three months, and a Genie<sup>®</sup> HD DVR.
                  With the Genie<sup>®</sup>, you can record as many as five
                  shows at once, and you can watch recordings in any room of
                  your house.† And with the capability to store up to 200 hours
                  of HD programs‡, you’ll never have to delete your favorite
                  shows to make room for new ones.
                </Typography>
                <Brandy
                  symbol={data.genieMiniDisclaimer.symbol}
                  text={data.genieMiniDisclaimer.text}
                  variant="legal"
                />
              </>
            }
            image={
              <Image
                data={{
                  ...data.motherSonImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.motherSonImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  DIRECTV Channels In {State}
                </Typography>
                <Typography>
                  Does your family love watching movies? How about sports and
                  news coverage? Whatever you love, DIRECTV in {State} has it
                  all. Not only does DIRECTV offer the most popular networks and
                  programming—you can also receive DIRECTV On Demand service and
                  a HD DVR. {`It's`} easy to pick your package. Just visit the
                  DIRECTV Channels page{' '}
                  <GatsbyLink to="/channels">here</GatsbyLink> to select the
                  channels you’re interested in and find the package that best
                  suits your {State} household.
                </Typography>
                <LinkButton
                  to="/channels"
                  CustomLink={GatsbyLink}
                  color="primary"
                  outlined
                >
                  See Channel Lineup
                </LinkButton>
              </>
            }
            image={
              <Image
                data={{
                  ...data.channelDesktopImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.channelDesktopImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={{
                  ...data.channelMobileImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.channelMobileImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  DIRECTV Vs. Dish <sup>®</sup>
                </Typography>
                <Typography>
                  Did you know that DIRECTV is offering {State} residents who
                  sign up now a Genie HD DVR with their service? The Genie Mini
                  is small and convenient, allowing you to connect up to eight
                  TVs from anywhere in your house—even from the porch or garage!
                  And since the Genie system runs on its own wireless signal, it
                  will never interfere with your {`home's`} Wi-Fi. DIRECTV also
                  gets you more of everything, from HD channels to equipment and{' '}
                  <GatsbyLink to="/packages">package choices</GatsbyLink>,
                  particularly when compared to DISH. For anyone that needs
                  further convincing, don’t forget that you’ll enjoy the most
                  comprehensive sports line-ups in {State}.
                </Typography>
                <Brandy
                  symbol={data.genieMiniDisclaimer.symbol}
                  text={data.genieMiniDisclaimer.text}
                  variant="legal"
                />
              </>
            }
            image={
              <Image
                data={{
                  ...data.devicesImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.devicesImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">Check Availability By City</Typography>
              </>
            }
            alignMainContent="Center"
            style={{ paddingTop: '0px' }}
          >
            <ItemBank className="city-link-bank">
              {cityDropdownData?.map((current, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Link to={current.value} key={i}>
                  {current.label}
                </Link>
              ))}
            </ItemBank>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbrev: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerUsDirectAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
          State_Abbreviation
        }
      }
    }
    brandyPackage(brandy_id: { eq: "dtv-entertainment" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    priceModalDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-modal-entertainment" }
    ) {
      brandy_id
      symbol
      text
    }
    heroDisclaimer: brandyDisclaimer(brandy_id: { eq: "disclaimer-inbody" }) {
      brandy_id
      symbol
      text
    }
    genieMiniDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-geniemini" }
    ) {
      brandy_id
      symbol
      text
    }
    cities: allDatasetManagerUsDirectAoa(
      filter: {
        State_Abbreviation: { eq: $stateAbbrev }
        Area_Type: { eq: "city" }
      }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroDesktopImage: contentfulMedia(
      contentful_id: { eq: "2E385f6nHQRN6USLF8G3iv" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroMobileImage: contentfulMedia(
      contentful_id: { eq: "5jH6OiKBMqcG5MXb9RaapH" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    sportsImage: contentfulMedia(
      contentful_id: { eq: "1G0go4qS4U8Q5msZ4pkqiv" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    motherSonImage: contentfulMedia(
      contentful_id: { eq: "50srECuc8dq0hjCy1GVm87" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    channelDesktopImage: contentfulMedia(
      contentful_id: { eq: "22LaKuJnrvyuKUzPO28Gb5" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    channelMobileImage: contentfulMedia(
      contentful_id: { eq: "2NJv1sn8An68vUKoW0zq5X" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    devicesImage: contentfulMedia(
      contentful_id: { eq: "5TPXz3PzTBFIF2Qt95Mrde" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "c72cbf4a-ddd3-5cf8-bc6f-60ae8af49a80" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "afaa43f7-6d91-56e9-ac4b-47edab6bfc1f" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
